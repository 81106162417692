import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.scss';
export default function ValueContainer(props) {
    return (
        <div
            className={[props.selectProps.classes.valueContainer, styles.valueContainer].join(' ')}
        >
            {props.children}
        </div>
    );
}

ValueContainer.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
};
